/* Built In Imports */
import { memo } from 'react';
/* External Imports */
import { Input, Textarea } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
/* Services */

/**
 * Renders the Input UI component.
 *
 * @param {string} field - field details.
 * @param {string} id - id.
 * @param {string} placeholder - placeholder.
 * @param {string} wd - width.
 * @param {boolean} textArea - if the input is an textarea.
 * @param {object} props - more styling properties
 * @returns {ReactElement} InputUI component.
 */

const InputUI = ({ field, id, placeholder, textArea, width, ...props }) => {
  return textArea ? (
    <>
      <Textarea
        {...field}
        id={id} // this can be a nanoid rather than props passing
        variant="flushed"
        placeholder={placeholder}
        border="none"
        width={width || { base: '100%', md: '188px' }}
        height="44px"
        rounded="0"
        fontSize="body3"
        margin="17px 0px"
        fontFamily="FedraSansStd-book"
        _focus={{}}
        bg="#C8B9A6"
        {...props}
      />
    </>
  ) : (
    <Input
      {...field}
      id={id} // this can be a nanoid rather than props passing
      variant="flushed"
      placeholder={placeholder}
      border="none"
      width={width || { base: '100%', md: '188px' }}
      height="44px"
      rounded="0"
      fontSize="body3"
      margin="10px 4.5px"
      fontFamily="FedraSansStd-book"
      _placeholder={{ color: '#957F68' }}
      _hover={{}}
      bg="#C8B9A6"
      {...props}
    />
  );
};

export default memo(InputUI);
